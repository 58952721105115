import React from "react";
import "./Footer.css";

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer-section py-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="contact-info" data-aos="fade-right">
              <h4 className="mb-4">Contact</h4>
              <div className="contact-item">
                <div className="contact-item-content">
                  <h5>Județ Bacău</h5>
                  <p>Strada Nicolae Bălcescu, Nr. 1218</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-content">
                  <h5>Contact</h5>
                  <p>Email: axx_rom@yahoo.com</p>
                  <p>Telefon: 0744547855</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-content">
                  <h5>Social Media</h5>
                  <div className="social-links">
                    <a
                      href="https://www.facebook.com/profile.php?id=61569419855610"
                      target="https://www.facebook.com/profile.php?id=61569419855610"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook"></i> Facebook
                    </a>
                    <a
                      href="https://www.linkedin.com/in/axx-rom-388b6a33a/"
                      target="https://www.linkedin.com/in/axx-rom-388b6a33a/"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i> LinkedIn
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="map-container" data-aos="fade-left">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2723.179547574204!2d26.9123285!3d46.5681404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b5702f67acfae3%3A0x41e672656dbeaa6b!2sLaundry%20cleaning%20AXX%20ROM%20Painting!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Location Map"
              />
            </div>
          </div>
        </div>

        <div className="footer-banner">
          <div className="web-links">
            <div className="url-links">
              <a href="http://www.regionordest.ro" className="web-link">
                www.regionordest.ro
              </a>
              <a
                href="https://www.facebook.com/Regio.NordEst.ro"
                className="web-link"
              >
                www.facebook.com/Regio.NordEst.ro
              </a>
            </div>
            <div className="aproape-button">Aproape de tine</div>
          </div>

          <div className="logo-row">
            <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
            <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
            <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
            <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
          </div>
          <button onClick={handleInfoClick} className="info-button">
            Proiect digitalizare - Află mai multe
          </button>
        </div>

        <div className="text-center mt-4" data-aos="fade-up">
          <p>Copyright ©2024 AXX-ROM. Toate drepturile rezervate.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
