import React from 'react';
import './SplineBackground.css';

const SplineBackground = () => {
  return (
    <div className="spline-container">
      <iframe
        src="https://my.spline.design/justawatersphere-cdb2cfbfa5bf622c20d152a6035a8129/"
        frameBorder="0"
        width="100%"
        height="100%"
        title="Spline Background"
      />
    </div>
  );
};

export default SplineBackground;