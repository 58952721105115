import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './FloatingButtons.css';

const FloatingButtons = () => {
  return (
    <div className="floating-icons">
      <a
        href="https://wa.me/40744547855"
        className="floating-icon"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a
        href="tel:+40744547855"
        className="floating-icon"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <FontAwesomeIcon icon={faPhone} />
      </a>
    </div>
  );
};

export default FloatingButtons;