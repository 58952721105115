import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <a href="/" className="navbar-brand">
          <img src="/logo.png" alt="AXX-ROM" className="logo" />
        </a>

        <button 
          className={`menu-toggle ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className={`navbar-nav ${isMenuOpen ? 'active' : ''}`}>
          <a 
            href="https://dashboard.curatatorie-haine.com" 
            className="login-btn"
          >
            LOGIN
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;