import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <div className="container">
        <h2 className="text-center mb-5">Serviciile Noastre</h2>
        <div className="row g-4">
          <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item">
              <i className="fas fa-tshirt service-icon"></i>
              <h4>Curățare și întreținere textile</h4>
              <p>
                Servicii profesionale de curățare pentru toate tipurile de
                textile
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item">
              <i className="fas fa-soap service-icon"></i>
              <h4>Spălare și călcare profesională</h4>
              <p>Tratament complet pentru hainele dumneavoastră</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item">
              <i className="fas fa-burn service-icon"></i>
              <h4>Curățare chimică</h4>
              <p>Tratamente speciale pentru articole delicate</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;