import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="glass-card" data-aos="fade-up">
          <h2 className="section-title">Despre AXX-ROM</h2>
          <p className="text-center mt-4">
            AXX-ROM SRL oferă servicii profesionale de spălare și curățare
            uscată a articolelor textile, asigurând calitate și atenție la
            detalii. Cu o echipă dedicată și soluții ecologice, ne concentrăm pe
            satisfacerea nevoilor fiecărui client, garantând rezultate
            impecabile și o experiență de curățare de top.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;