import React, { useEffect } from 'react';
import AOS from 'aos';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import './styles/globals.css';

import SplineBackground from './components/SplineBackground';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import FloatingButtons from './components/FloatingButtons';
import ChatSystem from './components/ChatSystem';
import Services from './components/Services';
import About from './components/About';
import Footer from './components/Footer';


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
    });
  }, []);

  return (
    <>
      <SplineBackground />
      <Navbar />
      <Hero />
      <FloatingButtons />
      <ChatSystem />
      <Services />
      <About />
      <Footer />

    </>
  );
}

export default App;
