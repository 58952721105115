import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="hero-content">
              <h1 className="hero-title">
                SERVICII<br />PROFESIONALE<br />DE SPĂLĂTORIE
              </h1>
              <p className="hero-subtitle">
                AXX-ROM oferă servicii profesionale de spălare și curățare
                uscată a articolelor textile, asigurând calitate și atenție la
                detalii.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;